<template>
    <div data-aos="fade-up" data-aos-duration="1000" class="flex items-center">
        <div class="w-1/3">
            <a href="{{link}}">
                <div :class="bg" class="bg-auto bg-center transform scale-95 hover:scale-105 transition-all duration-700 bg-no-repeat h-96">
                    <p class="text-main-grey text-center pt-64 text-xl">Visiter</p>
                </div>
            </a>
        </div>
        <div class="w-2/3 h-96 pt-24 px-8 leading-10">
            <h3 class="text-2xl text-center text-main-blue dark:text-main-light my-3">{{title}}</h3>
            <p class="text-main-grey dark:text-white leading-10 text-lg text-left">
                {{content}}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "LeftDesktopProject",
    props: ['title', 'content', 'link', 'bg']
}
</script>

<style scoped>

</style>