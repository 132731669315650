<template>
    <LeftDesktopProject
        title="Site e-commerce Pearle-dream"
        bg="bg-mockup-bijoux"
        content="Site e-commerce permettant d'administrer sa boutique en ligne, espace administration sur mesure. Vous pouvez visiter le site en version de démo en vous connectant
                avec le mail: 'admin@gmail.com' et le mot de passe: 'admin'."
        link="https://bijouterie-demo.xn--e-kiga-dev-c9a.fr/"
    ></LeftDesktopProject>
    <RightDesktopProject
        title="Site pour prise de contact"
        bg="bg-mockup-ramonage"
        content="Ab Ramonage a fait appel à moi afin de réaliser un site web permettant d'accélerer le lancement de son entreprise. Gain de crédibilité, chiffre d'affaire généré pour un petit budget"
        link="https://abramonage.com/"
    ></RightDesktopProject>
    <LeftDesktopProject
        title="Site e-commerce Click&Collect"
        bg="bg-mockup-pizzeria"
        content="Site pour une pizzeria qui durant la pandémie souhaitait une solution lui appartenant afin de maitriser les coûts. Vous pouvez visiter le site en version de démo en vous connectant
                avec le mail: 'admin@gmail.com' et le mot de passe: 'admin'."
        link="https://v2-pizza.xn--e-kiga-dev-c9a.fr/"
    ></LeftDesktopProject>
    <RightDesktopProject
        title="Site de reservation"
        bg="bg-mockup-euratech"
        content="Durant ma formation nous avions comme projet de fin de formation de réaliser un site de réservation à des ateliers. Vous pouvez visiter la démonstration et vous connecter avec le
        mail:'euratech@gmail.com' et le mot de passe: 'password'."
        link="https://euratech-demo.xn--e-kiga-dev-c9a.fr/"
    ></RightDesktopProject>
    <LeftDesktopProject
        title="Site vitrine friterie"
        bg="bg-mockup-friterie"
        content="Site pour une friterie permettant de présenter sa carte et sur mobile pouvoir lancer un appel depuis l'accueil afin de réserver."
        link="https://v1-friterie.xn--e-kiga-dev-c9a.fr/"
    ></LeftDesktopProject>
    <RightDesktopProject
        title="2 sites complémentaires dont un blog"
        bg="bg-mockup-shadow"
        content="Site réalisé à des fins pédagogiques afin de faciliter l'accueil d'un chiot dans une famille, complété par un blog administrable et permettant de mettre en privé certains articles"
        link="https://euratech-demo.xn--e-kiga-dev-c9a.fr/"
    ></RightDesktopProject>
</template>

<script>
    import LeftDesktopProject from "./LeftDesktopProject";
    import RightDesktopProject from "./RightDesktopProject";
    export default {
        name: 'Desktopprojects',
        components: {RightDesktopProject, LeftDesktopProject}
    };
</script>
